@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


* {
    font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
    width:5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color:#13161f; 

  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #08634e5c; 
    border-radius:7px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #08634e5c; 
  }

  /* index.css */

/* index.css */

/* Animation for the left-to-right glow */
@keyframes buttonGlow {
  0% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Initial shadow */
    transform: translateX(-50%);
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 255, 140, 0.762); /* Intermediate shadow */
    transform: translateX(50%);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Final shadow */
    transform: translateX(-50%);
  }
}

/* Animation for the hover effect */
@keyframes buttonHover {
  0% {
    transform: scale(1); /* Initial scale */
  }
  100% {
    transform: scale(1.05); /* Enlarged scale */
  }
}

/* Apply the left-to-right glow animation to the button */
body {
  background-color: #13161f; /* Set your desired background color */
  margin: 0; /* Remove default margin to ensure full width */
  padding: 0; /* Remove default padding to ensure full width */
}

/* Hover effect: when pointer hovers over the button */
/* Define styles for centering the main content */
.container {
  max-width: 75%; /* Set the maximum width of the container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 40px; /* Add some padding to the container */
  height: 100vh;

}
.intro{
  height: 90vh;

}

/* Styles for Header, Footer, and other components can be defined here */

.resume-button:hover {
  animation: buttonHover 0.3s forwards; /* Enlarge by 10% on hover */
  box-shadow: 0 0 5px #00e5fe; /* Full glow on hover */
  
}
.skill:hover{
  animation: buttonHover 0.3s forwards; /* Enlarge by 10% on hover */
  box-shadow: 0 0 5px #00e5fe; /* Full glow on hover */
}

.footer{
  font-family: monospace;
}
.slider{
  height: 100vh;
  margin-top: auto;
  position: relative;

}

.slider .list .item{
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}

.slider .list .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider .list .item::after{
  content:'';
  width: 100%;
  height: 100%;
  position:absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    to top, #000 10%, transparent
  );
}

.slider .list .item .content{
  position: absolute;
  left: 10%;
  top: 20%;
  width: 500px;
  max-width: 60%;
  z-index: 1;

}

.slider .list .item .content p:nth-child(1){
  text-transform: uppercase;
  letter-spacing: 10px;
}

.slider .list .item .content h2{
  font-size: 100px;
  margin: 0;
}

.slider .list .item.active{
  opacity: 1;
  z-index: 10;
  filter:blur(0);
}

@keyframes showContent {
  to{
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  } 
}

.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p:nth-child(3)
{
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s .7s ease-in-out 1 forwards;
}

.slider .list .item.active h2{
  animation-delay: 1s;
}

.slider .list .item.active p:nth-child(3){
  animation-delay: 1.3s;
}

.arrows{
  position: absolute;
  top: 30%;
  right: 50px;
  z-index: 100;
}

.arrows button{
  background-color: #eee5;
  border: none;
  font-family: monospace;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: x-large;
  color: #eee;
  transition: .5s;
}

.arrows button:hover{
  background-color: #eee;
  color: black;
}

.thumbnail{
  position: absolute;
  bottom: 0px;
  z-index: 11;
  display: flex;
  gap: 20px;
  width: 100%;
  height: 250px;
  padding: 0 50px;
  box-sizing: border-box;
  overflow: auto;
  justify-content: center;
  align-items: center;
  
}
.thumbnail .item img:hover{
  transform: scale(1.05);
}
.thumbnail::-webkit-scrollbar{
  width: 0;
}

.thumbnail .item{
  width: 100px;
  height: 140px;
  filter: brightness(.5);
  transition: .5s;
  flex-shrink: 0;
  filter: blur(1px);
}

.thumbnail .item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

}

.thumbnail .item.active{
  transform: scale(1.05);
  filter: blur(0);

}

.thumbnail .item .content{
  position: absolute;
  inset: auto 10px 10px 10px;
}

@media screen and (max-width: 1050px) { /* Adjusts for devices with width less than 768px */
  .container {
    max-width: 90% !important; /* Forces the container to occupy 90% of the screen width */

  }
  .intro, .slider { /* Assuming you want these elements to also occupy 90% width */
    width: 90% !important; /* Adjusts width to 90% for consistency */
  }

  .thumbnail{
    justify-content: start;
  }

  .slider .list .item .content h2{
    font-size: 60px;

  }

  .arrows{
    top: 10%;
  }

}




.header-container {
  position: fixed;
  top: -10px;
  left: 0;
  width: 100%;
  z-index: 999; /* Ensure the header stays on top of other content */
  transition: top 0.5s ease; /* Adjust transition duration and timing function */
}


.name {
  transition: transform 0.3s ease; /* Smooth transition for the transform property */
}

.name:hover{
  color: #00e5fe;
  transform: translate(-2px, -2px); /* Move 1px left and 1px up on hover */

}
.section-title:hover{
  color: #00e5fe;
}

.tag:hover{
  color: #00e5fe;

  transform: translate(-2px, -2px); /* Move 1px left and 1px up on hover */
  
}
.phrase{
  font-size: 12px;
}
.phrase:hover{
  color: #00e5fe;

  opacity: 100%;
  
}

.header-logo img{
  opacity: 60%;
  margin-left: 20px;
  margin-top: 10px;
}
.header-logo img:hover{
  transition: transform 0.3s ease;
  transform: translate(-2px, -2px); 
  opacity: 90%;
}

.header-right{
  padding: 10px;
  align-items: center;
  font-family: monospace;
  font-size: 12px;
  margin-right: 10px;
  color: #8892b0;
}
.header-right-btn:hover{
  color: #00e5fe
}

.company:hover{
  cursor: pointer;
  color: #00e5fe;
}

.t1:hover{
  cursor: pointer;
  color: #00e5fe;
}

.rsider{
  margin-right: -80px;
}
.rsider-text{
  margin-bottom: 90px;
  font-size: 10px;
  letter-spacing: 1px;
  opacity: 50%;
}

.rsider-text:hover{
  color: #00e5fe;
  opacity: 100%;
  
}

/* Initial state of the header */
/* Initial state of the header */

/* CSS class to hide the header */
.header-container.hidden {
  top: -80px; /* Adjust the height of the header as needed */
}

.about-me{
  padding-right: 20px;
  padding-bottom: 20px;
  
}
.about-me:hover{
  transition: transform 0.5s ease;
  transform: translate(16px, 16px);
}

.about-me img:hover{
  transition: transform 0.5s ease;
  transform: translate(-32px, -32px); 
}

.about-me:not(:hover){
  transition: transform 0.5s ease;

}

.about-me img:not(:hover){
  transition: transform 0.5s ease;

}

.about-text .about-text-2{
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: left;
  justify-content: left;
  margin-left: 0px;
}

.about-text-2 p{
  margin-left: 20px; 
  font-family: monospace;
  font-weight: 500;
}
.about-text-3{
  font-weight: 300;
  margin-top: 1px;

}
.about-text-2 p:hover{
  color: #00e5fe;
}

.ri-play-line{
  margin-left: -25px;
  padding: 5px;
  color: #00e5fe;
}
.ri-red-play-line{
  margin-left: -25px;
  padding: 5px;
  color: #fe4c00;
}
.ri-share-circle-line{
  font-size: 24px;
  color: #ccd6f6;
  margin-left: 10px;
  cursor: pointer;
}

.ri-share-circle-line:hover{
  transform: scale(1.02);
  color: #00e5fe;
}

.cloud-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

}

.tech-cloud {
  background-color: #8892b01e;
  color: #ccd6f6;
  padding: 4px 12px;
  border-radius: 20px;
  position: relative;
  font-family: monospace;
  font-size: 10px;
  cursor: pointer;
}
.tech-cloud:hover{
  color: #00e5fe;
}
.icons {
  cursor: pointer;
}

.icons:hover {
  transform: translate(1px, 1px);
  transition: transform 0.3s ease; /* Apply transition for smooth animation */
  color: #00e5fe;
}

#outer-div:hover .text-highlight{
  cursor: pointer;
  color: #00e5fe;
}
#outer-div:hover .text-highlight-main{
  cursor: pointer;
  color: #00e5fe;
  
}
.text-highlight-main:hover{
  font-weight: bolder;
}
.music-alert-main{
  border: solid 1px #00e5fe;
}
.music-alert{
  z-index: 1000;
  margin-left: 20px;

}

.music-alert:hover{
  color: #00e5fe;
}


/* Define font size for small screens */
@media screen and (max-width: 1050px) {
  .intro{
    padding-left: 10%;
  }
  .name{
    font-size: 26px;
  }
  .tag {
    font-size: 12px; /* Adjust the font size as needed for smaller screens */
  }
  .phrase{
    font-size: 8px;
    width:60%;
    word-wrap: break-word;
  }
  .contact{
    font-size: 9px;
  }

  .social-cloud-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Creates two columns */
    gap: 20px; /* Adjust the gap as needed */
    justify-items: center;
  }

  /* Optional: If you need the .tech-cloud buttons within this container to have specific styles */
  .social-cloud-container .tech-cloud {
    width: auto; /* Adjust as necessary */
    margin: 0; /* Removes any margin */
  }
  .header-container{
    top: -20px;
  }



}

.contact:hover{
  color: #00e5fe;
}

.proj-title:hover{
  color: #00e5fe;
}

.skill{
  font-size: 10px;
}

.contact-page {
   /* Set the height to cover the entire viewport */
  
  background-color: #13161f; /* Set your desired background color */
  justify-content: center;
  align-items: center;
  text-align: center;


}

.contact-page-down{
  padding: 10vh;
  justify-content: center;
  align-items: center;
  text-align: center;

  
}




@media screen and (max-width: 1050px) {
  .content .item .active{
    font-size: 1px;
  }
  .slider .list .item img {
    width: 100%; /* Full width of its parent */
    max-height: 300px; /* Limits the height, adjust based on your need */
    object-fit: cover; /* Ensures the image covers the area, might crop */
  }

  .slider .list .item.active {
    /* Assuming you want the active item to stand out */
    transform: scale(0.9); /* Slightly smaller */
    box-shadow: 0 0 10px rgba(9, 9, 9, 0.5); /* Soft glow for emphasis */
  }

  .slider .list .item {
    display: flex; /* Ensures children (like your image) are flex children */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    overflow: hidden; /* Prevents image overflow */
    border-radius: 10px; /* Optional: Adds rounded corners to the container */
  }

}

.cloud-container-skill {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;

}

.tech-cloud-skill {
  background-color: #8892b01e;
  color: #ccd6f6;
  padding: 4px 12px;
  border-radius: 20px;
  position: relative;
  font-family: monospace;
  font-size: 12px;
  cursor: pointer;
}

.tech-cloud-skill:hover{
  color: #00e5fe;
  transform: scale(1.05);
}


.social-icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.social-icon img {
  width: 40px;
  height: 40px;
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
  z-index: 999; /* Below the popup but above other content */
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  max-width: 400px;
  background-color: #13161f;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; 
  padding: 20px;
  box-sizing: border-box;
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-content {
  text-align: center;
  color: #ccd6f6;
  font-weight: 300;
  font-size: 10px;
  font-family: monospace;
}

.popup-content p {
  margin-bottom: 10px;
  margin: 10px;
  padding-bottom: 2px;
}


.popup-content i {
  margin-right: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.popup-content p:hover{
  color: #00e5fe;
}

.email-form{
  display: flex;
  flex-direction: column;
  font-family: monospace;
  margin: 15px;
  font-size: 12px;
  

}

input, textarea{
  margin: 10px 0px;
  padding: 0.5vh;

}



.send-button{
  margin-left: 80px;
  margin-right: 80px;
  
}